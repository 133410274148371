.Main {
    width: 70%;
    height: 100%;
    position: relative;
    transition: all 0.5s;
    margin-left: 15vw;
    align-items: center;
    display: flex;
    flex-direction: column;
  .top-banner{
      width: 100%;
      height: auto;
  }

  .scroll-to-top-icon {
    cursor: pointer;
    position: fixed;
    right: 120px;
    bottom: 70px;
    width: 40px;
    height: 40px;
    display: none;
  }

 
    .page {
      display: none;
      &.active {
        display: flex;
      }
    }

    .mapCover {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99999;  
      &.hidden {
        display: none;
      }
    }
    .popup-loading-appear {
      opacity: 0.01;
    }
    
    .popup-loading-appear.popup-loading-appear-active {
        animation: fadeIn 1000ms;
    }

    .loading-container {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: var(--transparent-black);
      z-index: 20;
      display: none;
    }
    
    .loading-active {
        display: block;
    }
    
    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
    }
    
    .subContainer{
      //height: calc(100% - 361px);
      height: 100%;
      width: 100%;
    }
  
    .handWritingKeyboard {
        height: 380px;
        width: 860px;
        left: 40px;
        position: absolute;
        bottom: 20px;
        display: none;
        /* background: #bbb5b5d8; */
        padding: 0 0px;
        z-index: 1;
        .colLeft {
          /* width: 460px;
          height: 345px; */
          text-align: center;
          /* display: table-cell; */
          /* vertical-align: middle; */
          position: absolute;
          margin: auto;
          right: 110px;
          top: 15px;
  
          .drawingBoard {
              width: 640px;
              height: 350px;
              cursor: crosshair;
              clear: both;
              overflow: hidden;
              background-color: rgb(231, 231, 231);
              /* margin: auto; */
              /* position: absolute; */
              /* top: 20px; */
              top: 0;
              border-color: rgba(72, 166, 217, 0);
              border-width: 3px;
              border-style: dotted;
              transition: all 100ms;
          }
          .commands {
            position: absolute;
            top: 0;
            right: 20px;
            .cmdClear {
                top: 80px;
                color: var(--primary-color);
                font-weight: 800;
                font-size: 20px;
                width: 60px;
                padding: 5px;
                margin-top: 20px;
                border-radius: 5px;
                background-color: #FCFCFC;
            }
            
            .cmdUndo {
                color: var(--primary-color);
                font-weight: 800;
                font-size: 20px;
                width: 60px;
                padding: 5px;
                border-radius: 5px;
                margin-top: 20px;
                background-color: #FCFCFC;
            }
          }
        }
        .hanziContainer {
          background-color: #FCFCFC;
          display: table-cell;
          width: 240px;
          height: 360px;
          vertical-align: middle;
          position: absolute;
          right: -140px;
          overflow-y: auto;
          overflow-x: hidden;
          top: 15px;
          opacity: 1;
          -webkit-transition: 300ms;
          transition: 100ms;
          border: 1px solid var(--primary-color);
          border-radius: 10px;
          /* padding: 10px; */
          .originalHanzi,
          .newHanzi,
          .deepLearningResults {
              position: absolute;
              height: 360px;
              width: 240px;
              text-align: left;
              /* padding-left: 30px; */
              left: 5px;
              .hanziButton {
                width: 60px;
                height: 60px;
                border-radius: 40px;
                background-color: #f4f4f4;
                /* margin: 32px -12px; */
                margin-bottom: 20px;
                font-size: 30pt;
                color: var(--primary-color);
                border: none;
                margin-right: 20px;
                transition: all 100ms;
                outline: none;
                border: none;
            }
          }
  
          .originalHanzi :active,
          .newHanzi  :active{
              // box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.55);
          }
  
      }
      .hidden {
        display: none;
        transition: 500ms;
      }
    }
  
    .hide-keyboad{
      display: none;
    }
  }

  @media only screen and (max-width: 1100px){
    .Main {
      width: 100%;
      margin-left: 0;
    }
  }
