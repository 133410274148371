.OrderInfoPage{
    width: 100%;
    background-color: #204690;
    font-size: 24px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    line-height: 1.5;

    div{
        max-width: 60%;
        margin-top: 10px;
    }
    
    .tick-icon{
        width: 100px;
    }
    .title{
        font-size: 70px;
    }
    a.whatsapp {
        text-decoration: none;
        font-weight: bold;
        color: white;
    }
    .sample-images{
        width: 100%;
        div{
            max-width: 100%;
            width: 100%;
            text-align: left;
            margin-top: 60px;
            padding: 0 20px;
        }
        img{
            width: calc(50% - 40px);
            height: auto;
            margin: 20px;
        }
    }

}

@media only screen and (max-width: 640px){
    .OrderInfoPage{
        font-size: 16px;
        padding: 40px 0;
        div{
            max-width: 80%;
        }

        .tick-icon{
            width: 40px;
        }
        .title{
            font-size: 20px;
        }
        .sample-images{
            div{
                margin-top: 20px;
                padding: 0 10px;
            }
            img{
                width: calc(50% - 20px);
                height: auto;
                margin: 10px;
            }
        }
    }
}
