.App {
    text-align: center;
    overflow: hidden;
    // font-family: Boysen, 'Microsoft JhengHei UI';
    font-weight: normal;
    transition: all 0.5s;
    color: var(--text-color);
    // font-family: "Open Sans", "Helvetica Neue", "Microsoft JhengHei UI", Verdana, Arial, sans-serif;
    &.portrait {
        height: 1920px;
        width: 1080px;
    }

    &.landscape {
        height: 1080px;
        width: 1920px;
    }

    .container {
        display: none;
        &.active {
            display: inherit;
        }
    }
}

.app-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: var(--transparent-white); */
}

@font-face {
    font-family: Boysen;
    src: url('./assets/fonts/Boysen.woff2') format('woff2'),
            url('./assets/fonts/Boysen.woff') format('woff'),
            url('./assets/fonts/Boysen.ttf') format('truetype'),
            url('./assets/fonts/Boysen.eot') format('embedded-opentype'),
            url('./assets/fonts/Boysen.svg') format('svg');
    font-weight: normal;
}

@font-face {
    font-family: Boysen;
    src: url('./assets/fonts/Boysen-Bold.woff2') format('woff2'),
            url('./assets/fonts/Boysen-Bold.woff') format('woff'),
            url('./assets/fonts/Boysen-Bold.ttf') format('truetype'),
            url('./assets/fonts/Boysen-Bold.eot') format('embedded-opentype'),
            url('./assets/fonts/Boysen-Bold.svg') format('svg');
    font-weight: bold;
}

@font-face {
    font-family: Boysen;
    src: url('./assets/fonts/Boysen-Light.woff2') format('woff2'),
            url('./assets/fonts/Boysen-Light.woff') format('woff'),
            url('./assets/fonts/Boysen-Light.ttf') format('truetype'),
            url('./assets/fonts/Boysen-Light.eot') format('embedded-opentype'),
            url('./assets/fonts/Boysen-Light.svg') format('svg');
    font-weight: 100;
}

:root {
    --primary-color: #41B6B6;   /*green*/
    --secondary-color: #098181; /*dark green*/
    --third-color: #cc9e3c; /*mustard*/
    --transparent-white: rgba(252, 252, 252, 0.15);
    --transparent-black: rgba(0, 0, 0, 0.5);
    --white-box-shadow: 5px 5px 15px rgb(199, 199, 199);
    --dark-box-shadow: 4px 4px 15px rgb(129, 129, 129);
    --grey: #959595;
    --white: #ffffff;
    --text-color: #ffffff;
    --active-tab-text-color: #195F5F;

    --edge-padding: 80px;
}

::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
  
