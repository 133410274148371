.BottomBar{
    width: 100vw;
    background-color: #1586BE;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    a{
        text-decoration:none;
        color: #FFFFFF;
    }
    .row{
        display: flex;
        width: calc(100% - 120px);
        padding: 0 60px;
        align-items: flex-start;
        justify-content: space-between;
        .col{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 30%;
            p{
                text-align: left;
            }
            .title{
                font-size: 24px;
                border-bottom: 2px solid #FFFFFF;
                margin-bottom: 10px;
                line-height: 1.5;
            }

            div{
                line-height: 1.5;
                font-size: 20px;
                a{
                    text-decoration:none;
                    color: #FFFFFF;
                }
            }
        }
    }

    .copyright{
        margin-top: 40px;
    }


}

@media only screen and (max-width: 640px){
    .BottomBar{
        .row{
            display: flex;
            width: calc(100% - 30px);
            padding: 0 20px;
            flex-direction: column;
            .col{
                max-width: 100%;
                margin-bottom: 20px;
                .title{
                    font-size: 14px;
                    line-height: 1.5;
                }
    
                div{
                    line-height: 1.5;
                    font-size: 14px;
                }
            }
        }

        .copyright{
            font-size: 10px;
            margin-top: 40px;
            width: 90%;
            margin-left: 5%;
        }
    
    }
}
